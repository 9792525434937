(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CheckoutController', CheckoutController);

    function CheckoutController($scope, $state, $stateParams, $window, $exceptionHandler, authentication, config, model, billing, team, utils, plansService, stripeService, cfpLoadingBar, sfSetupPanel) {

        if (model.me.team.subscribed) {
            return $state.go('settings.billingSettings.overview');
        }

        $scope.onAndroid = ($window.device && $window.device.platform === 'Android');
        $scope.billingFrequencyObject = {
            billingFrequency: 'annually'
        };
        $scope.discount = {};
        $scope.currency = model.me.team.billing_currency;
        $scope.payButtonEnabled = false;
        $scope.checkoutPrice = null;
        $scope.hasAppSumoCode = false;
        $scope.chargeVAT = false;
        $scope.plans = plansService.get();
        $scope.selectedPlan = $stateParams.plan ? $stateParams.plan : 'pro'; // TODO: what should be the back up?
        $scope.showMonthlyOption = $stateParams.plan !== 'enterprise';
        $scope.copyRightYear = new Date().getFullYear();

        // Used for when the customer tries to subscribe but then fails SCA auth and needs to try again, this way we don't try to create a subscription multiple times but we update it
        // this also means that we will lock down the plan and discount field since we already have subscription and just need an updated card
        $scope.alreadyHasSubscription = false;

        let doingCheckout = false;
        let discountObject = {};
        let token;
        let vatNumber;
        const closeTabOnFinish = $stateParams.closeTabOnFinish;

        if (!$scope.onAndroid) {
            init();
        }

        $scope.calculateCheckoutPrice = function () {

            const discount = calculateDiscount();

            const checkoutPrice = ($scope.price[$scope.billingFrequencyObject.billingFrequency].pricePerBillingCycle * $scope.amountOfEnabledUsersInTeam) - discount;

            if ($scope.chargeVAT) {
                $scope.checkoutPrice = checkoutPrice * 1.21;
            }
            else {
                $scope.checkoutPrice = checkoutPrice;
            }
        };

        $scope.pay = function () {

            if (!token) {
                return $exceptionHandler(new Error('called .pay before a Stripe token was passed'));
            }

            $scope.payButtonEnabled = false;
            doingCheckout = true;

            const customer = { token: token.id };

            customer.plan = utils.getStripePlanId($scope.selectedPlan, $scope.billingFrequencyObject.billingFrequency, $scope.currency.iso);

            if (vatNumber) {
                customer.vat = vatNumber;
            }

            if (token.card.country) {
                customer.country_code = token.card.country;
            }

            if ($scope.discount && $scope.discount.isReferralCode) {
                customer.refer = $scope.discount.code;
            }

            if ((!$scope.discount.code || ($scope.discount.isReferralCode)) && !$scope.appliedPromoCode) {
                return createCustomer(customer);
            }

            return billing.validateDiscountCode($scope.appliedPromoCode || $scope.discount.code, $scope.plans[$scope.selectedPlan].id).then(function (response) {

                discountObject = response.data;

                if (angular.equals(discountObject, {})) {
                    $scope.payButtonEnabled = true;
                    doingCheckout = false;
                    return utils.showErrorToast('Invalid discount code');
                }

                $scope.calculateCheckoutPrice();

                if ($scope.teamCodeIsAlreadyApplied || (discountObject && !discountObject.expired && !discountObject.redemption_limit_reached)) {
                    let discountCodeIsApplicable;

                    if ($scope.billingFrequencyObject.billingFrequency === 'monthly') {
                        discountCodeIsApplicable = discountObject.monthly.discount_applicable;
                    }
                    else {
                        discountCodeIsApplicable = discountObject.yearly.discount_applicable;
                    }

                    if (discountCodeIsApplicable) {
                        customer.discount_code = discountObject.code;
                    }
                }

                return createCustomer(customer);
            });
        };

        $scope.applyDiscount = function (discountCode) {

            const callWasMadeWithPromoCode = discountCode === $scope.model.me.team.promo_code;

            return billing.validateDiscountCode(discountCode, $scope.plans[$scope.selectedPlan].id).then(function (response) {

                if (response.data.referral_code) {
                    $scope.discount.isReferralCode = true;
                    return utils.showSuccessToast(`Referral code ${discountCode} has been applied successfully!`);
                }

                discountObject = response.data;

                if (angular.equals(discountObject, {})) {
                    if (callWasMadeWithPromoCode) {
                        $scope.discount = {};
                        return;
                    }

                    return utils.showErrorToast('Invalid discount code');
                }

                $scope.discount.isReferralCode = false;
                $scope.discount.infoText = '';
                $scope.teamCodeIsAlreadyApplied = (model.me.team.discount_code && model.me.team.discount_code.toLowerCase() === discountObject.code.toLowerCase());
                $scope.appliedPromoCode = (model.me.team.promo_code && model.me.team.promo_code.toLowerCase() === discountObject.code.toLowerCase() && model.me.team.promo_code);

                $scope.calculateCheckoutPrice();

                if (discountObject.expired && !$scope.teamCodeIsAlreadyApplied) {
                    return utils.showErrorToast('This discount code seems to have expired');
                }

                if (discountObject.redemption_limit_reached && !$scope.teamCodeIsAlreadyApplied) {
                    return utils.showErrorToast('This discount code has been redeemed already');
                }

                //  Don't fill in when it's a promo code so people don't share their codes
                if (!$scope.appliedPromoCode) {
                    $scope.discount.code = discountCode;
                }

                $scope.discount.monthly = discountObject.monthly;
                $scope.discount.yearly = discountObject.yearly;
                $scope.discount.infoText = discountObject.description || '';

                if (discountObject.type === 'firstFreeThen50Off' && discountObject.code.toLowerCase().includes('sumo')) {
                    $scope.hasAppSumoCode = true;
                    $scope.price.annually.totalPricePerBillingCycle = $scope.price.annually.pricePerBillingCycle * ($scope.amountOfEnabledUsersInTeam - 1);
                    $scope.price.monthly.totalPricePerBillingCycle = $scope.price.monthly.pricePerBillingCycle * ($scope.amountOfEnabledUsersInTeam - 1);
                }
                else {
                    $scope.hasAppSumoCode = false;
                }

                if (!$scope.teamCodeIsAlreadyApplied && !$scope.appliedPromoCode) {
                    return utils.showSuccessToast('Discount code ' + $scope.discount.code + ' has been applied successfully!');
                }
            });
        };

        $scope.switchFrequency = function (billingFrequency) {

            $scope.billingFrequencyObject.billingFrequency = billingFrequency;

            return $scope.calculateCheckoutPrice();
        };

        $scope.onCardValidated = function ($event) {

            token = $event.token;
            vatNumber = $event.VATNumber;
            $scope.chargeVAT = $event.chargeVAT;
            $scope.currency = $event.currency;

            $scope.calculateCheckoutPrice();

            $scope.payButtonEnabled = !doingCheckout && token;
        };

        $scope.onCardValidateError = function ($event) {

            $scope.payButtonEnabled = false;
            $scope.chargeVAT = false;

            token = null;
            vatNumber = null;

            if ($event) {
                return utils.showErrorToast($event.error.message);
            }
        };

        $scope.onProcessingChanged = function ($event) {

            if (!doingCheckout) {
                $scope.payButtonEnabled = !$event.processing && token;
            }
        };

        function initPriceObject() {

            if ($scope.selectedPlan) {
                $scope.price = angular.copy($scope.plans[$scope.selectedPlan].price);
            }
            else {
                $scope.price = {
                    annually: {
                        pricePerMonth: 30,
                        pricePerBillingCycle: 360
                    },
                    monthly: {
                        pricePerMonth: 35,
                        pricePerBillingCycle: 35
                    }
                };
            }

            $scope.price.annually.totalPricePerBillingCycle = $scope.price.annually.pricePerBillingCycle * $scope.amountOfEnabledUsersInTeam;
            // Enterprise doesn't have a monthly option
            if ($scope.price.monthly) {
                $scope.price.monthly.totalPricePerBillingCycle = $scope.price.monthly.pricePerBillingCycle * $scope.amountOfEnabledUsersInTeam;
            }
        }

        function init() {

            return team.get().then(function (response) {

                $scope.amountOfEnabledUsersInTeam = response.data.enabled_user_count;
                initPriceObject();

                if (response.data.discount || response.data.promo) {
                    $scope.applyDiscount(response.data.promo || response.data.discount);
                    return;
                }

                $scope.calculateCheckoutPrice();
            });
        }

        function createCustomer(customer) {

            // Show loading as billing update + SCA might take some time
            cfpLoadingBar.start();

            let billingPromise;
            if ($scope.alreadyHasSubscription) {
                billingPromise = billing.update({ source: customer.token, vat: customer.vat || null });
            }
            else {
                billingPromise = billing.create(customer);
            }

            return billingPromise.then(function (response) {

                $scope.alreadyHasSubscription = true;

                // `Requires action` basically means we need to do a SCA flow
                if (response.data.status === 'requires_action') {
                    // If we already have a subscription the new card will require a setup intent and not a payment intent
                    return stripeService.stripe[response.data.type === 'setup_intent' ? 'handleCardSetup' : 'handleCardPayment'](response.data.client_secret).then(function (result) {

                        // Hide loading as we are done
                        cfpLoadingBar.complete();

                        if (result.error) {
                            utils.showErrorToast(result.error.message);
                            // Log the error
                            const formattedError = new Error(result.error.code);
                            formattedError.data = result.error;
                            $exceptionHandler(formattedError);

                            doingCheckout = false;
                            // Keep it false so we force the customer to change something about their card details which will give us a new token
                            // and thus prevents using the same token twice
                            $scope.payButtonEnabled = false;
                            token = undefined;
                            return;
                        }

                        // We can't rely on Stripe web hooks always arriving to the server faster than when we call the server to re-fetch data
                        // That's why we want to explicitly sync data with Stripe first, before re-fetching.
                        // If we don't do this, it's likely that the SCA_REQUIRED restriction dialog will show up until the user refreshes.
                        return billing.syncStripeData().then(function (syncResponse) {

                            if (syncResponse.error) {
                                // Log the error
                                const formattedError = new Error(result.error.code);
                                formattedError.data = result.error;
                                $exceptionHandler(formattedError);
                            }

                            return doneWithCheckout();
                        });
                    });
                }

                cfpLoadingBar.complete();

                return doneWithCheckout();
            }, function () {

                // Hide loading as we are done
                cfpLoadingBar.complete();

                doingCheckout = false;
                $scope.payButtonEnabled = true;
            });
        }

        function doneWithCheckout() {

            if (config.segment && config.segment.key) {
                analytics.track('subscribed', { gaClientId: model.gaClientId });
            }

            return authentication.fetchMe().then(function () {

                sfSetupPanel.updateSetupSteps();

                let successToastText = 'Congratulations! You can now use Salesflare’s ';

                if ($scope.selectedPlan) {
                    if ($scope.selectedPlan === 'growth') {
                        successToastText += 'Growth plan';
                    }
                    else if ($scope.selectedPlan === 'pro') {
                        successToastText += 'Pro plan';
                    }
                    else if ($scope.selectedPlan === 'enterprise') {
                        successToastText += 'Enterprise plan';
                    }
                }
                else {
                    successToastText += 'full';
                }

                successToastText += ' functionality.';

                utils.showSuccessToast(successToastText).then(function () {

                    // When checkout was reached from a second tab ('compare plans' from upgrade dialog), still show the toast, but then close the tab.
                    if (closeTabOnFinish) {
                        // We set closeme in storage here to let the parent window know we are done. See `utils.popup`.
                        store.set('closeme', true);
                    }
                });

                // Only go here when we don't want to close the tab. Otherwise we have two tabs of the Salesflare app
                if (!closeTabOnFinish || !!$window.isMobile) {
                    return $state.go('settings.billingSettings.overview', { subscribedFromCheckout: true });
                }
            });
        }

        function calculateDiscount() {

            let discount;

            if (angular.equals(discountObject, {}) || ((discountObject.expired || discountObject.redemption_limit_reached) && !$scope.teamCodeIsAlreadyApplied)) {
                discount = 0;
            }
            else if ($scope.billingFrequencyObject.billingFrequency === 'monthly') {
                discount = discountObject.monthly.discount;
            }
            else {
                discount = discountObject.yearly.discount;
            }

            $scope.discount.amount = discount;

            return discount;
        }
    }
})();
