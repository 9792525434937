(function () {
    'use strict';

    angular
        .module('salesflare.components.filters.rule', [])
        .component('sfFiltersRule', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/filters/rule/filtersRule.html',
            bindings: {
                index: '<',
                appliedRule: '<',
                first: '<',
                last: '<',
                pipeline: '<',
                entity: '<',
                group: '<',
                filterFields: '<',
                deleteRule: '&',
                onRuleUpdate: '&',
                onApply: '&'
            }
        });

    function controller($timeout, $state, sfWalkthrough, model) {

        const vm = this;
        let firstSearch = true;

        vm.searchText = '';
        vm.filterProperties = [];
        vm.currentUser = model.me;
        vm.isDisabled = false;
        vm.noEditAllowed = false;

        vm.$onInit = function () {

            vm.entities = {
                accounts: 'account',
                contacts: 'contact',
                opportunities: 'opportunity',
                tags: 'tag',
                tasks: 'task',
                campaigns: 'campaign',
                workflows: 'workflow'
            };
            vm.entityPlural = vm.entity === 'opportunity' ? 'opportunities' : (vm.entity + 's');
            vm.chosen = false;

            if (vm.appliedRule && vm.appliedRule.id) {
                vm.property = vm.appliedRule;
                vm.property.index = vm.index;

                if (vm.property.display_entity) {
                    vm.property.entity_ui = vm.property.display_entity;
                }
                else if (vm.property.entity.toLowerCase() === 'person') {
                    vm.property.entity_ui = 'Contact';
                }
                else {
                    vm.property.entity_ui = vm.property.entity.charAt(0).toUpperCase() + vm.property.entity.slice(1);
                }

                vm.chosen = true;

                return checkIfDisabled();
            }
            else {
                return orderFilterFields();
            }
        };

        vm.$onChanges = function (changes) {

            if (changes.filterFields) {
                if (vm.appliedRule && vm.appliedRule.id && angular.isUndefined(vm.property)) {
                    vm.property = vm.appliedRule;
                    vm.property.index = vm.index;

                    if (vm.property.display_entity) {
                        vm.property.entity_ui = vm.property.display_entity;
                    }
                    else if (vm.property.entity.toLowerCase() === 'person') {
                        vm.property.entity_ui = 'Contact';
                    }
                    else {
                        vm.property.entity_ui = vm.property.entity.charAt(0).toUpperCase() + vm.property.entity.slice(1);
                    }

                    vm.chosen = true;
                }
                else if (changes.filterFields.previousValue && !angular.equals({}, changes.filterFields.previousValue)) {
                    // Reorder the filter fields when they change
                    return orderFilterFields();
                }

                checkIfDisabled();
            }
        };

        // Get matches for search text in property auto complete
        vm.getMatches = function (searchText, elementScope) {

            let matches = [];

            vm.filterProperties.forEach(function (filterProperty) {

                let title = filterProperty.title;

                if (title === 'Person') {
                    title = 'Contact';
                }

                let entityMatches;

                if (!searchText) {
                    entityMatches = filterProperty.properties;
                }

                if (title && searchText && title.toLowerCase().includes(searchText.toLowerCase())) {
                    entityMatches = filterProperty.properties;
                }

                if (!entityMatches && searchText) {
                    entityMatches = filterProperty.properties.filter(function (property) {

                        return (property.label.toLowerCase().includes(searchText ? searchText.toLowerCase() : ''));
                    });
                }

                const subHeader = {
                    title,
                    entity: filterProperty.entity,
                    label: title,
                    subheader: true
                };
                entityMatches = entityMatches.map(function (property) {

                    if (!property.title) {
                        /*
                        If (!property.display_entity) {
                            property.entity = filterProperty.title;
                        }
                        */
                        property.entity_ui = title;
                    }

                    return property;
                });

                if (entityMatches.length > 0) {
                    entityMatches.unshift(subHeader);
                }

                matches = [...matches, ...entityMatches];
            });

            vm.standardPropertyList = matches;

            // Workaround for autocomplete performance with a lot of items (see https://github.com/angular/material/issues/6384)
            if (firstSearch) {
                firstSearch = false;
                //Return full list of matches in next tick otherwise a lot of watchers are created
                $timeout(function () {
                    // Return a copy of the matches because we don't want the chosen property being bound to its filter field
                    elementScope.$$childHead.$mdAutocompleteCtrl.matches = angular.copy(matches); // eslint-disable-line angular/no-private-call
                }, 0);

                // Only return first 10 items (md-dropdown-items = 8) immediately so only a couple of watchers get created, see comment on $timeout above
                return angular.copy(matches.slice(0, 10));
            }

            return angular.copy(matches);
        };

        vm.delete = function () {

            return vm.deleteRule();
        };

        // When a property gets changed, bring the news to filtersGroup
        vm.onPropertyChange = function ($event) {

            vm.property.operator = $event.operator;
            vm.property.value = $event.value;
            vm.property.raw_value = $event.raw_value;

            checkIfDisabled();

            return vm.onRuleUpdate({ newRule: vm.property });
        };

        // Helper function for autocomplete
        vm.selectedItemChange = function (property) {

            if (!property || property.title) {
                vm.searchText = '';
                return;
            }

            if (property && !property.title) {
                vm.chosen = true;
            }
        };

        /**
         * Helper function to get the string extension for the filter field label when needed
         * Only needed for disabled custom fields at the moment.
         * TODO: This could be handled by the server,
         * but not until implementation of the api_name for custom fields in advanced filtering
         *
         * @param {{ enabled: Boolean }} [property]
         * @returns {String}
         */
        vm.getLabelExtension = function (property) {

            if (angular.isDefined(property) && property.enabled === false) {
                return ' (disabled field)';
            }

            return '';
        };

        vm.showPipelineNotice = function () {

            return vm.entityPlural === 'opportunities' && vm.property && (vm.property.id === 'pipeline.id' || vm.property.id === 'opportunity.pipeline.id');
        };

        /**
         * Doing this based on the state is not the nicest solution but neither was passing down a value through 5 components.
         */
        const pipelineNoticeMap = {
            'opportunities': 'Please select the pipeline at the top of the page.',
            'report': 'Please use the dashboard filters for this.'
        };
        /**
         * @returns {String}
         */
        vm.getPipelineNoticeText = function () {

            return Object.entries(pipelineNoticeMap).find(function (keyValue) {

                return $state.current.name.toLowerCase().includes(keyValue[0]);
            })[1];
        };

        ////////////////////////

        function orderFilterFields() {

            // Order the entities will be shown in. The current entity will get priority over the rest
            const groupOrder = ['Account', 'Contact', 'Opportunity', 'Task', 'Tag', 'Campaign', 'Workflow'];

            // Get the filter fields for the current entity
            if (!sfWalkthrough.isShowing()) {
                // Make sure contacts maps on person (server response)
                vm.entities.contacts = 'person';

                // Put the entity we are filtering on first in the property list
                const entityProperties = {
                    title: vm.group,
                    entity: vm.entity,
                    properties: angular.copy(vm.filterFields).filter(function (property) {

                        return !property.display_entity && property.group === vm.group;
                    })
                };

                // Get the order of the filter properties correct
                const indexToDelete = groupOrder.indexOf(vm.group);

                // Reset list before adding new properties again
                vm.filterProperties = [entityProperties];

                if (indexToDelete > -1) {
                    groupOrder.splice(indexToDelete, 1);
                }

                // Add the rest of the properties in the usual order of entities
                groupOrder.forEach(function (group) {

                    vm.filterProperties.push({
                        title: group,
                        entity: group.toLowerCase(),
                        properties: angular.copy(vm.filterFields).filter(function (property) {

                            if (property.display_entity) {
                                return property.display_entity.toLowerCase() === group.toLowerCase();
                            }

                            return property.group.toLowerCase() === group.toLowerCase();
                        })
                    });
                });
            }
        }

        /**
         * Check if a rule needs to be shown as disabled or not
         * or if custom fields are disabled or enabled
         */
        function checkIfDisabled() {

            const enabledRule = vm.filterFields.find(function (field) {

                if (angular.isDefined(vm.appliedRule.pipeline) && angular.isDefined(field.pipeline) && vm.appliedRule.pipeline !== field.pipeline) {
                    return false;
                }

                return (field.id === vm.appliedRule.id || field.query_builder_id === vm.appliedRule.id);
            });

            if (!enabledRule && vm.appliedRule.id && !vm.appliedRule.id.includes('custom')) {
                vm.noEditAllowed = true;
            }

            // New rules should always be enabled so that they can be filled in by the user
            // Disable rule when no matching filter field was found for the rule id
            // If a rule was found enable the rule
            if (!vm.appliedRule.id) {
                vm.isDisabled = false;
            }
            else if (!enabledRule && !vm.noEditAllowed) {
                vm.isDisabled = true;
            }
            else {
                vm.isDisabled = false;
            }

            if (vm.appliedRule.archived) {

                vm.isDisabled = true;
            }

            if (!vm.appliedRule.search_entity && (vm.appliedRule.raw_value || []).map((value) => value.archived).includes(true)) {

                vm.isDisabled = true;
            }

            if (vm.property && vm.property.disabled !== vm.isDisabled) {
                vm.property.disabled = vm.isDisabled;
                vm.onRuleUpdate({ newRule: vm.property });
            }

            // Enabled only applies to custom fields, disabled for filter rules in general
            if (vm.property && enabledRule && angular.isDefined(vm.property.enabled)
                && angular.isDefined(enabledRule.enabled) && vm.property.enabled !== enabledRule.enabled) {
                vm.property.enabled = enabledRule.enabled;
                vm.appliedRule.enabled = enabledRule.enabled;
                vm.onRuleUpdate({ newRule: vm.property });
            }
        }
    }
})();
