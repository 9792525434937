<form #authenticationCodeForm="ngForm" id="authenticationCodeForm" name="authenticationCodeForm">
    <sfx-toolbar [dialogToolbar]="true" (closeEvent)="close()">Two-factor authentication</sfx-toolbar>
    <div mat-dialog-content>
        <p>Open the two-factor authentication app on your phone to view your 6-digit authentication code.</p>
        <mat-form-field>
            <mat-label>Authentication code</mat-label>
            <input #codeInput="ngModel" required matInput name="authenticationCode" [(ngModel)]="authenticationCode">
            <mat-error *ngIf="codeInput.hasError('required')">
                Authentication code is required
            </mat-error>
            <mat-error *ngIf="codeInput.hasError('invalidAuthenticationCode')">
                The authentication code was incorrect
            </mat-error>
        </mat-form-field>
        <p><a class="recovery-anchor" (click)="close({ action: 'SHOW_RECOVERY_CODE_DIALOG' })">Having problems? Use a recovery code instead.</a></p>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button type="submit" (click)="verify()">Verify</button>
    </div>
</form>
