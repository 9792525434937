(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('datasources', datasourcesService);

    function datasourcesService(sfHttp, config) {

        this.getEmailType = function (email) {

            return sfHttp.get(config.apiUrl + 'datasources/email/type', { params: { email } });
        };

        this.getEmailSources = function (options) {

            const request = {
                params: {}
            };

            if (options) {
                // Object.hasOwn is not supported in older browsers
                if (Object.prototype.hasOwnProperty.call(options, 'includeTeamDataSources')) {
                    request.params.includeTeamDataSources = options.includeTeamDataSources;
                }
            }

            return sfHttp.get(`${config.apiUrl}datasources/email`, request);
        };

        this.deleteEmailSource = function (sourceId) {

            return sfHttp.delete(config.apiUrl + 'datasources/email/' + sourceId);
        };

        this.updateEmailSource = function (sourceId, emailSource) {

            return sfHttp.put(config.apiUrl + 'datasources/email/' + sourceId, emailSource);
        };

        this.trade = function (data, query) {

            return sfHttp.post(config.apiUrl + 'datasources/connect/trade', data, { params: query }).then(function (response) {

                response.data = new URLSearchParams(response.data);

                return response;
            });
        };

        this.getConnectInfo = function (type) {

            return sfHttp.get(config.apiUrl + 'datasources/connect/info', { params: { type } });
        };

        this.createSMTPSource = function (data) {

            return sfHttp.post(config.apiUrl + 'datasources/smtp', data);
        };

        this.getMessage = function (dataSourceId, emailMessageId) {

            return sfHttp.get(config.apiUrl + 'datasources/' + dataSourceId + '/emails/' + emailMessageId);
        };

        this.sendResearcherInviteEmail = function (email) {

            return sfHttp.get(config.apiUrl + 'datasources/connect?type=security&email=' + email);
        };

        this.createResearcherTeam = function (token, email, timeZone) {

            return sfHttp.post(config.apiUrl + 'datasources/connect/security', { token, email, time_zone: timeZone });
        };
    }
}());
